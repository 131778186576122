import React, {Component} from 'react';
import {Textfit} from "react-textfit";
import '../../styles/css/ProgressButton.css';

class AnswerItemsComponent extends Component {

    render() {
        const itemsToIterate = this.props.answers || [];
        const tenantVariables = this.props.tenantVariables || {};
        const question = this.props.question || {};
        const questions = this.props.questions || [];
        const fromScoreboard = this.props.fromScoreboard || false;
        if(question.answers){
            itemsToIterate.sort((a,b)=>a.order-b.order)
        }
        return (
            <div className="grid-container user" style={{gridAutoFlow:"row", transform:"translateY(0%)", padding:0, justifyItems: !this.props.sideBigScreen && "center"}}>
                {
                    itemsToIterate.map(function(item,i){
                        let container;
                        if(document.getElementsByClassName('grid-item')){
                            container = document.getElementsByClassName('grid-item')[0];
                        }
                        let progressWidth = "0";
                        let progressBarColor = tenantVariables.answerProgressBarClient || "transparent";
                        let backgroundColor='transparent';
                        let questionToEdit = this.props.questionToEdit || {};
                        let scoreboardBackgroundColor = tenantVariables.answerBackgroundColor || 'transparent';
                        let answerTextColor = tenantVariables.answerTextColor || "transparent";
                        let answerBorderColor = tenantVariables.answerOutlineColor || "transparent";
                        let showRightOrWrong= false;
                        let responseText = "";
                        let totalCount = 0;
                        let totalAnswers = 0;
                        let percent = "0%";
                        const questionCount = this.props.questionCount;
                        if(question.answers){
                            totalCount = questionCount[question.id].totalPicks;
                            totalAnswers = questionCount[question.id].answers[item.id];
                        } else {
                            for(let questionIndex in questionCount){
                                for(let answerIndex in questionCount[questionIndex].answers){
                                    if(item.id === answerIndex){
                                        totalCount = questionCount[questionIndex].totalPicks
                                        totalAnswers = questionCount[questionIndex].answers[answerIndex]
                                    }
                                }
                            }
                        }
                        if(container && totalCount>0){
                            progressWidth = parseInt(container.clientWidth * totalAnswers/totalCount, 10)
                        }
                        if(item.shown && item.correct){
                            backgroundColor = tenantVariables.correctTextColor;
                            responseText = "👍";
                            showRightOrWrong = true
                        } else if(item.shown && !item.correct){
                            backgroundColor = tenantVariables.incorrectTextColor;
                            responseText = "👎";
                            showRightOrWrong = true
                        }
                        let questionText = "";
                        if(questions && questions.length > 0){
                            const currentQuestion = questions[i];
                            if(currentQuestion && currentQuestion.answers && currentQuestion.answers.length > 0){
                                questionText = currentQuestion.questionText;
                            }
                        }
                        if(fromScoreboard){
                            progressBarColor = tenantVariables.progressBarColor || "transparent";
                            answerTextColor = tenantVariables.progressBarTextColor || "transparent";
                            scoreboardBackgroundColor = tenantVariables.progressBarBackgroundColor || "transparent";
                            if(this.props.screenToShow === 2 && item.correct){
                                scoreboardBackgroundColor = tenantVariables.progressBarEndWinColor || "transparent";
                                progressBarColor = tenantVariables.progressBarEndWinColor || "transparent";
                            }
                        }
                        if(totalCount > 0){
                            percent = (parseInt(totalAnswers/totalCount*100,10)).toString()+"%";
                        }
                        return <>
                            {!fromScoreboard &&
                                <div style={{width: "100%", display:"flex", justifyContent:'start', backgroundColor:"transparent", color:answerTextColor, paddingLeft: 5, paddingRight: 5, textAlign: "left"}}>
                                    <span>{questionText}</span>
                                </div>
                            }
                            {item.emptyAnswer &&
                                <div
                                    key={i}
                                    id={item.id}
                                    className="grid-item answerItemClass"
                                    onClick={()=>this.props.toggleShowAlternateAnswers()}
                                    style={{background: scoreboardBackgroundColor, color:answerTextColor, boxShadow:"unset", outline:"1px solid "+(fromScoreboard?answerTextColor:answerBorderColor), display:"flex", justifyContent:'center', maxWidth: 800, minWidth:280, lineHeight:1,border:questionToEdit && questionToEdit.id === item.id ? "1px yellow solid" : "none",padding:0,borderRadius:0,zIndex:-2, width: "100%"}}>
                                    <div className="progress-button-container" id="progress-button-container" style={{overflow:"visible", display: this.props.show_percentages?"":"none"}}>
                                        <div className="progress-container" style={{width:progressWidth, backgroundColor:progressBarColor, zIndex:-1, borderRadius:0}}/>
                                    </div>
                                    <div style={{display:"grid", gridTemplateColumns:item.answerImage?"repeat(1,1fr 10fr 1fr)":"repeat(1,10fr 1fr)", gridGap:5, alignItems: "center", textAlign:"center"}}>
                                        <img src={item.answerImage} width={fromScoreboard?"100px":"58px"} height={fromScoreboard?"100px":"58px"} alt="" style={{marginLeft:5, display:item.answerImage?"":"none"}}/>
                                        <Textfit mode="multi" min={fromScoreboard?34:12} max={fromScoreboard?60:item.answerImage?32:40} style={{height:fromScoreboard?90:60,alignItems:"center",display:"flex",marginLeft:5}}>
                                            Select Answer
                                        </Textfit>
                                        {showRightOrWrong && !fromScoreboard ? (
                                            <span style={{backgroundColor:backgroundColor, height:80, width:80,display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",fontSize:40}}>
                                            {responseText}
                                        </span>
                                        ) : (
                                            <span style={{visibility: this.props.show_percentages ? "visible" : "hidden"}}>
                                          {/*<div className="col-md-12" style={{padding:0, fontSize:12, display:fromScoreboard?"none":""}}>*/}
                                          {/*  <span>Fans Picked</span>*/}
                                          {/*</div>*/}
                                          {/*<div className="col-md-12" style={{padding:0}}>*/}
                                          {/*  <span style={{fontSize:"1.5em", width:"100%", padding:5}}>{percent}</span>*/}
                                          {/*</div>*/}
                                        </span>
                                        )
                                        }
                                    </div>
                                </div>
                            }
                            {!item.emptyAnswer &&
                                <div
                                    key={i}
                                    id={item.id}
                                    className="grid-item answerItemClass"
                                    onClick={()=>this.props.onClickAnswer(item, i)}
                                    style={{background: scoreboardBackgroundColor, color:answerTextColor, boxShadow:"unset", outline:"1px solid "+(fromScoreboard?answerTextColor:answerBorderColor), display:"flex", justifyContent:'start', maxWidth: 800, minWidth:280, lineHeight:1,border:questionToEdit && questionToEdit.id === item.id ? "1px yellow solid" : "none",padding:0,borderRadius:0,zIndex:-2, width: "100%"}}>
                                    <div className="progress-button-container" id="progress-button-container" style={{overflow:"visible", display: this.props.show_percentages?"":"none"}}>
                                        <div className="progress-container" style={{width:progressWidth, backgroundColor:progressBarColor, zIndex:-1, borderRadius:0}}/>
                                    </div>
                                    <div style={{display:"grid", gridTemplateColumns:item.answerImage?"repeat(1,1fr 10fr 1fr)":"repeat(1,10fr 1fr)", gridGap:5, alignItems: "center", textAlign:"left"}}>
                                        <img src={item.answerImage} width={fromScoreboard?"100px":"58px"} height={fromScoreboard?"100px":"58px"} alt="" style={{marginLeft:5, display:item.answerImage?"":"none"}}/>
                                        <Textfit mode="multi" min={fromScoreboard?34:12} max={fromScoreboard?60:item.answerImage?32:40} style={{height:fromScoreboard?90:60,alignItems:"center",display:"flex",marginLeft:5}}>
                                            {item.answerText}
                                        </Textfit>
                                        {showRightOrWrong && !fromScoreboard ? (
                                            <span style={{backgroundColor:backgroundColor, height:80, width:80,display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",fontSize:40}}>
                                            {responseText}
                                        </span>
                                        ) : (
                                            <span style={{visibility: this.props.show_percentages ? "visible" : "hidden"}}>
                                          <div className="col-md-12" style={{padding:0, fontSize:12, display:fromScoreboard?"none":""}}>
                                            <span>Fans Picked</span>
                                          </div>
                                          <div className="col-md-12" style={{padding:0}}>
                                            <span style={{fontSize:"1.5em", width:"100%", padding:5}}>{percent}</span>
                                          </div>
                                        </span>
                                        )
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    }, this)
                }
            </div>
        );
    }
}

export default AnswerItemsComponent;
